import '../App.css';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>Film Retreats</h3>
        <p>
          Coming Soon
        </p>
      </header>
    </div>
  );
}

export default Home;
